import { isArray } from 'lodash';
import { replace } from '../replace';
import { validate } from '@fingermarkglobal/validation';
// creates a function that resets the state by replacing
// the current state with an updated version
// pre and post functions for cleaning up the product tree at various stages of its life

// no operation
const pass = ({ item }) => item;

const createUpdater = ({ set, process = pass }) => ({
  uid,
  handler,
  pre = pass,
  post = pass,
} = {}) => {
  validate({ name: 'createUpdater', paramaters: { set, uid, handler } });

  set(items => {
    const edit = item => {
      const preItem = pre({ uid, item });
      const product = replace({ uid, handler, item: preItem });
      const postItem = post({ item: product });
      const processedItem = process({ item: postItem });

      return processedItem;
    };

    if (isArray(items)) return items.map(edit);
    else return edit(items);
  });
};

export { createUpdater };
