import { assign } from 'xstate';

const handlePayment = assign((context, event) => {
  const { data } = event || {};
  const { fields = {} } = data || {};

  const info = {
    transactionId: fields.ecrRcptNum,
    authCode: fields.approvalCode,
    cardNumber: fields.cardNumber,
    cardType: fields.cardSchemeName,
    amount: fields.amount,
    messNumber: fields.messNumber,
    ecrRcptNum: fields.ecrRcptNum,
  };

  return {
    ...context,
    transactionId: info.transactionId,
    timestamp: fields.datetime,
    response: fields,
    result: 'SUCCESS',
    info,
    message: fields.responseMessage,
  };
});

export { handlePayment };
