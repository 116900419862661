import Dinero from 'dinero.js';

const join = (...params) => (separator = ' | ') => {
  return params.filter(param => !!param).join(separator);
};

const formatPrice = ({ price, currency, defaultPrefix = true } = {}) => {
  // string value will be `price not found` so return that to display
  if (typeof price === 'string') return price;

  const amount = Math.round((price || 0) * 100);
  const options = { amount };
  return Dinero(currency ? { ...options, currency } : options).toFormat(
    `${defaultPrefix ? '$' : ''}0,0.00`,
  );
};

const round = value => Math.round(value * 100) / 100;

const formatAmountInCents = ({ amount }) => Math.round((amount * 100 + Number.EPSILON) * 100) / 100;

export { join, formatPrice, round, formatAmountInCents };
