import { validate } from '@fingermarkglobal/validation';

const registerSuccess = ({ path } = {}) => async () => {
  const registration = await window.navigator.serviceWorker.register(path);
  logger.log('ServiceWorker registration successful with scope: ', registration.scope);
};

const registerError = err => logger.error('ServiceWorker registration failed: ', err);

const register = ({ path = null } = {}) => {
  validate({ name: 'Service Worker Registration', parameter: { path } });

  if (!('serviceWorker' in window.navigator)) return;

  window.addEventListener('load', registerSuccess({ path }), registerError);
};

export { register };
