import stringify from 'stringify-object';
import { validate } from '@fingermarkglobal/validation';
import { interpret as original } from 'xstate';

const interpret = ({ name = null, machine = null, transition = null } = {}) => {
  validate({ name: 'interpret', parameters: { name, machine } });

  const instance = original(machine)
    .onTransition(state => {
      logger.log(`[${name}] state: ${state.value} event: ${stringify(state.event)}`);
      if (transition) transition(state);
    })
    .start();

  const run = ({ event = null, config = null } = {}) => {
    validate({ name: 'run', parameters: { event } });
    instance.send(event, config);
  };

  return {
    run,
    instance,
  };
};

export { interpret };
