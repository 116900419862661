import { request } from '@fingermarkglobal/request';

class HealthTasks {
  constructor({ serial = null, interval = 10, settings = null } = {}) {
    if (!serial) throw new Error(`Invalid 'serial' passed to 'HealthTasks'`);
    if (!settings) throw new Error(`Invalid 'settings' passed to 'HealthTasks'`);

    this.serial = `${serial}`.replace(':', ''); // Icinga doesnt handle `:` in strings
    this.interval = interval;
    this.settings = settings;

    this.init();
  }

  init() {
    this.batcher = setInterval(async () => {
      try {
        await this.pingHealthService();
      } catch (err) {
        logger.log('Error in health service', err);
      }
    }, this.interval * 1000);
  }

  async pingHealthService() {
    const { customer: organisation } = this?.settings?.settings?.datasource || {};
    const endpoint = process.env.POI_APP_HEARTBEAT_API;

    if (!endpoint || !organisation) return null;

    return request
      .post(endpoint, {
        json: {
          config: {
            pretty: true,
            exit_status: 0,
            type: 'Service',
            performance_data: [],
            plugin_output: 'PING OK - Packet loss = 0%',
            filter: `host.name=="${this.serial}.${organisation}.fingermark.tech"`,
          },
        },
      })
      .json();
  }
}

export { HealthTasks };
