import { assign } from 'xstate';
import { TransactionResult } from '../../../utils';

const createPayload = assign((context, event) => {
  return event;
});

const handleRefund = assign((context, event) => ({ ...context, ...event.data }));

const respondAfter = (resolve, reject, data, timeout) => {
  setTimeout(() => {
    if (data.result === TransactionResult.SUCCESS) {
      resolve(data);
    } else {
      reject(data.errorCondition);
    }
  }, timeout);
};

const executeRefund = context =>
  new Promise((resolve, reject) => {
    const response = {};

    const result = TransactionResult.SUCCESS;
    const errorCondition = null;
    const timeout = 5 * 1000;

    const { amount } = context;

    const defaultData = {
      result,
      errorCondition,
      response,
    };

    switch (true) {
      case amount >= 170 && amount <= 179.99: {
        const data = {
          ...defaultData,
          result: TransactionResult.NOT_ALLOWED,
          errorCondition: 'payment refund failure',
        };

        return respondAfter(resolve, reject, data, timeout);
      }
      case amount >= 180 && amount <= 189.99: {
        const data = {
          ...defaultData,
          result: TransactionResult.NOT_ALLOWED,
          errorCondition: 'payment refund failure (timeout)',
        };

        return respondAfter(resolve, reject, data, 100 * 1000);
      }
      case amount >= 190 && amount <= 199.99: {
        const data = {
          ...defaultData,
          result: TransactionResult.NOT_ALLOWED,
          errorCondition: 'payment refund failure (after 15s)',
        };

        return respondAfter(resolve, reject, data, 15 * 1000);
      }
      default:
        return respondAfter(resolve, reject, defaultData, timeout);
    }
  });

export { createPayload, handleRefund, executeRefund };
