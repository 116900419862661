import { check, validate } from '@fingermarkglobal/validation';
import { interpret, getJwtToken } from '@fingermarkglobal/utilities';
import { request } from '@fingermarkglobal/request';
import { postOrderMachine as machine } from './machines/post-order';
import { buildRequestOptions } from './helpers';

const doPostOrder = ({ payload = null, timeout = 30, transition = null }) => {
  validate({ name: 'doPostOrder', parameters: { payload } });

  const { instance: postOrderMachine } = interpret({
    name: 'postOrderMachine',
    machine,
    transition,
  });

  // IIFE so we can return the running machine
  (async () => {
    const functionName = 'post-order';

    const invalidParams = check({
      name: 'Post-order',
      parameters: { payload },
    });

    postOrderMachine.send('INITIALISE');

    if (invalidParams) {
      postOrderMachine.send('INITIALISE_ERROR', {
        message: `[${functionName}] Fail to validate parameters [${invalidParams}]`,
      });
    }

    const endpoint = process.env.POI_APP_HAWKE_POST_ORDER_ENDPOINT;

    if (!endpoint)
      postOrderMachine.send('INITIALISE_ERROR', {
        message: `[${functionName}] Failed to get post order endpoint in the environment file with the key POI_APP_HAWKE_POST_ORDER_ENDPOINT.`,
      });

    postOrderMachine.send('INITIALISE_SUCCESS');

    try {
      const jwtToken = await getJwtToken();

      if (!jwtToken) {
        postOrderMachine.send('PROCESS_ERROR', {
          message: `[${functionName}] Request made successfully, but an empty response was received`,
        });
      }

      logger.log(`[post-order] request payload: ${JSON.stringify(payload, null, 2)}`);

      const { endpoint: formattedEndpoint, params } = buildRequestOptions({
        endpoint,
        payload,
        timeout,
        jwtToken,
      });

      const response = await request.post(formattedEndpoint, params).json();

      if (!response) {
        postOrderMachine.send('PROCESS_ERROR', {
          message: `[${functionName}] Request failed. Response {${response}}`,
        });
      }

      logger.log(`[post-order] response payload: ${JSON.stringify(response, null, 2)}`);

      postOrderMachine.send('PROCESS_SUCCESS', { message: response });
      postOrderMachine.send('IDLE');
    } catch (error) {
      const throwError = message =>
        postOrderMachine.send('PROCESS_ERROR', {
          message: `[${functionName}] Failed to handle request [${message}]`,
        });

      if (error?.response) error.response.json().then(data => throwError(data.message));
      // if contains `response` prop it is http error
      else throwError(error);
    }
  })();

  return { postOrderMachine };
};

export { doPostOrder };
