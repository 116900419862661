import { send } from '@fingermarkglobal/protocols';
import { getCardNumber } from './card-number';
import { handleResponseStatus } from './handle-response-status';
import { startPinging, wait } from './ping';

const handleCommandCode = async ({
  commandCode,
  data,
  transactionObject,
  port,
  printResponseMessage,
  readyToPrintResponseMessage,
  purchaseMessage,
  statusMessage,
  receiptRequestMessage,
  socket,
  responseHandler,
  pingerId,
}) => {
  switch (commandCode) {
    /*
     * CP: Pinpad on
     * RP?: Ready to print, please ack
     * PT?: Payment receipt sent, confirm
     * RS: Status response (ping)
     */
    case 'CP': {
      if (pingerId) break;

      await send(purchaseMessage, port, socket, responseHandler);

      const pinpadOnRespose = {
        pingerId: startPinging(statusMessage, port, socket, responseHandler),
        transactionObject: { ...transactionObject },
      };

      return pinpadOnRespose;
    }
    case 'RP?=': {
      await send(readyToPrintResponseMessage, port, socket, responseHandler);
      break;
    }
    case 'PT?': {
      const printData = data.toString().split(',');
      const receiptData = printData[1];

      const cardNumber = await getCardNumber(receiptData);

      const receiptInfo = {
        paymentReceipt: receiptData,
        cardNumber,
      };

      const objectWithReceiptInfo = { ...transactionObject, ...receiptInfo };

      await send(printResponseMessage, port, socket, responseHandler);

      const printResponse = {
        pingerId,
        transactionObject: { ...transactionObject, ...objectWithReceiptInfo },
      };

      return printResponse;
    }
    case 'GR': {
      const receiptInfo = {
        paymentReceipt: transactionObject.paymentReceipt,
        cardNumber: null,
        result: 'SUCCESS',
      };

      if (!transactionObject.cardNumber) {
        const printData = data.toString().split(',');
        const receiptData = printData[3];

        receiptInfo.cardNumber = await getCardNumber(receiptData);
      }

      const objectWithReceiptInfo = { ...transactionObject, ...receiptInfo };

      return {
        pingerId,
        transactionObject: { ...transactionObject, ...objectWithReceiptInfo },
      };
    }
    case 'RS': {
      const handledResponseStatus = handleResponseStatus(data);

      // This is filled only when payment is successful
      if (Object.keys(handledResponseStatus.rawResponse).length > 0) {
        await wait(1000);

        await send(receiptRequestMessage, port, socket, responseHandler);

        return {
          pingerId,
          transactionObject: { ...transactionObject, ...handledResponseStatus },
        };
      }

      break;
    }
    default:
      throw new Error(`Command received from payment device not found.`);
  }

  return { pingerId, transactionObject: { ...transactionObject } };
};

export { handleCommandCode };
