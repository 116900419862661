import { assign } from 'xstate';

const handlePayment = assign((context, event) => {
  const { request, config } = context || {};
  const { amount, transactionId } = request || {};

  const { rawResponse } = event?.data || {};

  const merDescData = rawResponse.MerDescData.split(':');
  const [, authCode] = merDescData;

  const info = {
    transactionId,
    cardNumber: rawResponse.MaskedPan,
    cardType: rawResponse.CardType,
    amount: parseInt(amount, 10),
    authCode,
  };

  const response = {
    transactionId,
    timestamp: new Date().toISOString(),
    result: 'SUCCESS',
    config,
    response: rawResponse || {},
    info,
    message: '',
  };

  return response;
});

export { handlePayment };
