import { validate } from '@fingermarkglobal/validation';

// Evaluate the current cart / product / tree
// and update counts, price, etc
const evaluate = ({ product, products: siblings = [], categoryMax } = {}) => {
  validate({ name: 'Cart Evaluate', paramater: { product } });
  const {
    options,
    customisations,
    count,
    isRootProduct = false,
    price,
    validated,
    default: initial,
  } = product;
  const { current, max, min } = count;

  const sinblingsTotal = siblings.reduce(
    (previous, current) => previous + current.count.current,
    0,
  );
  const categoryAddDisabled = categoryMax && categoryMax !== -1 && sinblingsTotal >= categoryMax;
  const productAddDisabled = max !== -1 && current >= max;
  const disabledActions = {
    removeDisabled: (isRootProduct && current === 1) || current <= min,
    addDisabled: categoryAddDisabled || productAddDisabled,
  };

  const selected = current > 0;

  const formattedCustomisations = customisations.map(category => {
    const { products, count } = category;
    const { max, min } = count;
    const evaluated = products.map(product => evaluate({ product, products, categoryMax: max }));
    const totalCount = evaluated.reduce((result, current) => result + current.count.current, 0);
    const totalPrice = evaluated.reduce((result, current) => result + current.price.current, 0);
    const ready = totalCount >= min && totalCount <= max;
    return {
      ...category,
      count: { ...count, current: totalCount },
      price: { current: totalPrice },
      ready,
      products: evaluated,
    };
  });

  const formattedOptions = options.map(option => {
    const { products, count } = option;
    const evaluated = products.map(product => evaluate({ product }));
    const totalCount = evaluated.reduce((result, current) => result + current.count.current, 0);
    const totalPrice = evaluated.reduce((result, current) => result + current.price.current, 0);
    const ready = evaluated.every(product => product.ready);
    return {
      ...option,
      count: { ...count, current: totalCount },
      price: { current: totalPrice },
      ready,
      products: evaluated,
    };
  });

  const customisationsTotalPrice = formattedCustomisations.reduce(
    (acc, curr) => acc + curr.price.current,
    0,
  );
  const optionsTotalPrice = formattedOptions.reduce((acc, curr) => acc + curr.price.current, 0);

  const priceCalc = initial ? price.initial : price.unit;

  const currentPrice = validated
    ? price.current
    : count.current * (priceCalc + optionsTotalPrice + customisationsTotalPrice);

  const updatedPrice = {
    ...price,
    current: currentPrice,
  };

  const customisationsReady = formattedCustomisations.every(category => category.ready);
  const optionsReady = formattedOptions.every(option => option.ready);

  return {
    ...product,
    ...disabledActions,
    selected,
    ready: customisationsReady && optionsReady,
    customisations: formattedCustomisations,
    options: formattedOptions,
    price: updatedPrice,
    evaluated: true,
  };
};

export { evaluate };
