import { USBClient } from '@fingermarkglobal/protocols';
import { parseMessage } from './utilities';

const executeRequest = ({ config = {}, request = {} } = {}) => {
  const { body = {} } = request || {};

  const buffer = Buffer.from(JSON.stringify(body)).toString('hex');

  const { port = {} } = config || {};

  const { settings } = config || {};
  const { port: usbPort, timeout, vidPid } = settings || {};

  const [vid, pid] = vidPid.split(':');

  const usbClientConfig = {
    vid,
    pid,
    timeout,
    usbPort,
  };

  const usbClient = new USBClient({ port, config: usbClientConfig });

  const sendRequest = async () => {
    try {
      const { payload: response } = await usbClient.send({
        payload: { buffer },
        bufferTimeout: 250,
      });

      const parsedResponse = parseMessage(response);

      if (parsedResponse.rawResponse) {
        logger.log(`[im20] request finish ${JSON.stringify({ parsedResponse })}`);
        return parsedResponse;
      }
    } catch (error) {
      logger.error(`[im20] request error ${JSON.stringify(error)}`);
      throw new Error(error);
    } finally {
      if (usbClient?.connectionId) await usbClient.disconnect();
    }

    return null;
  };

  return sendRequest();
};

export { executeRequest };
