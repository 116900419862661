import { destructRefund } from '../../destructerers';
import { executeRequest } from '../request';

const executeRefund = async context => {
  // take built payload
  const { config } = context;

  // make request
  logger.log(`[adyen] refund request: ${JSON.stringify(context, null, 2)}`);

  const adyenResponse = await executeRequest({ context });

  logger.log(`[adyen] refund response: ${JSON.stringify(adyenResponse, null, 2)}`);

  // handle destruct
  // return standard format
  const { result, errorCondition, response } = destructRefund({ response: adyenResponse, config });
  if (result === 'success') return Promise.resolve({ result, errorCondition, response });
  // reject normal object instead of error to trigger `onError`
  // event but pass on to generic payload handler
  return Promise.reject({ result, errorCondition, response });
};

export { executeRefund };
