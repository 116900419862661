import { send } from '@fingermarkglobal/protocols';

const startPinging = (data, port, socket, responseHandler) => {
  return setInterval(async () => {
    await send(data, port, socket, responseHandler);
  }, 3000);
};

const stopPinging = pingerId => {
  clearInterval(pingerId);
};

const wait = async miliseconds => {
  return new Promise(resolve => setTimeout(resolve, miliseconds));
};

export { startPinging, stopPinging, wait };
