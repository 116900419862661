import { nanoid } from 'nanoid';
import {
  createAdder,
  createEditor,
  createRemover,
  createSelector,
  createSetValidatedValues,
  createRemoverAll,
} from '../format/handlers';
import { reformatCategory } from './utilities';

import { validate } from '@fingermarkglobal/validation';

// `reformat` goes from a formatted product and updates with the changes
// passed through (new product, different `update`)
const reformat = ({
  product,
  update,
  category = null,
  isRootProduct = false,
  categoryUid = null,
} = {}) => {
  validate({ name: 'Cart Reformat', paramaters: { product, update } });

  const { id, options = [], products = [], uid = nanoid(), customisations = [] } = product;

  // if `category` is present it comes from a customisation
  // category, this means it has extra maxes
  const { max: categoryMax = null } = category || {};

  const remove = createRemover({ uid, update });
  const select = createSelector({ uid, update, categoryUid });
  const add = createAdder({ uid, update, categoryMax });
  const removeAll = createRemoverAll({ uid, update });
  const edit = createEditor({ uid, update });
  const setValidatedValues = createSetValidatedValues({ uid, update });

  const formatted = products.map(product => reformat({ product, update }));

  const formattedOptions = options.map(category => reformatCategory({ category, update, id }));

  const formattedCustomisations = customisations.map(category =>
    reformatCategory({ category, update, id }),
  );

  return {
    ...product,
    add,
    remove,
    select,
    removeAll,
    edit,
    setValidatedValues,
    isRootProduct,
    validated: false,
    products: formatted,
    options: formattedOptions,
    customisations: formattedCustomisations,
    evaluated: false,
  };
};

export { reformat };
