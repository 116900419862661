import { createMachine } from 'xstate';
import { SerialMachineContext } from '@fingermarkglobal/types';

import {
  cacheSerialResolve,
  querySerialResolve,
  chromeSerialResolve
} from '../../utilities/serial';

import {
  setSerialAction,
  setQueryErrorMessage,
  setCacheErrorMessage,
  setChromeErrorMessage,
  sendParentFailureAction,
  sendParentCompletedAction,
  persistSerialToCacheAction
} from './actions';

const serialMachine = createMachine<SerialMachineContext>(
  {
    id: 'serial-machine',
    initial: 'chrome',
    context: {
      port: null,
      serial: null,
      origin: null,
      message: null
    },
    states: {
      chrome: {
        invoke: {
          src: chromeSerialResolve,
          onDone: {
            target: 'success',
            actions: ['setSerialAction']
          },
          onError: {
            target: 'query',
            actions: ['setChromeErrorMessage']
          }
        }
      },
      query: {
        invoke: {
          src: querySerialResolve,
          onDone: {
            target: 'success',
            actions: ['setSerialAction']
          },
          onError: {
            target: 'cache',
            actions: ['setQueryErrorMessage']
          }
        }
      },
      cache: {
        invoke: {
          src: cacheSerialResolve,
          onDone: {
            target: 'success',
            actions: ['setSerialAction']            
          },
          onError: {
            target: 'failure',
            actions: ['setCacheErrorMessage']
          }
        }
      },
      success: {
        entry: ['persistSerialToCacheAction', 'sendParentCompletedAction'],
        type: 'final'
      },
      failure: {
        entry: ['sendParentFailureAction'],
        type: 'final'
      }
    }
  },
  {
    actions: {
      setSerialAction,
      setQueryErrorMessage,
      setCacheErrorMessage,
      setChromeErrorMessage,
      sendParentFailureAction,
      sendParentCompletedAction,
      persistSerialToCacheAction
    }
  }
);

export { serialMachine };
