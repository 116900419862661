import { receiver } from '@fingermarkglobal/chrome-messages';
import { request as requester } from '@fingermarkglobal/request';

class HTTPServer {
  listen() {
    receiver({ handler: this.receive });
  }

  static async receive({ request }) {
    const { id, payload = null } = request;

    // https://github.com/sindresorhus/ky#kyinput-options
    const response = await requester(payload);

    return { id, payload: response };
  }
}

export { HTTPServer };
