const handleResponseStatus = data => {
  const responseCode = data.toString().split(',')[3];

  /**
   * Response codes
   * BB: Busy
   * ??: Processing, try again
   * 00, 09: Payment approved
   * CC: Payment cancelled
   */

  switch (responseCode) {
    case 'BB':
      throw new Error('Pinpad busy');
    case '??':
      return { rawResponse: {} };
    case '00':
    case '09': {
      const response = {
        rawResponse: { payload: data },
      };

      return response;
    }
    case 'CC':
    case 'CANCELLED':
    default:
      throw new Error('Payment cancelled');
  }
};

export { handleResponseStatus };
