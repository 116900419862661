import { HTTPClient } from '@fingermarkglobal/protocols';

const executeRequest = async ({ context } = {}) => {
  const { request: payload, config } = context;
  const { settings, port } = config;
  const { apiKey, poiId, test = true, timeout, isLocal = true } = settings;

  const environment = test ? 'test' : 'live';

  const endpoint = isLocal
    ? `https://${poiId}.${environment}.terminal.adyen.com:8443/nexo`
    : `https://supersonic-zodac-bqqvoxpupq-ew.a.run.app/https://terminal-api-${environment}.adyen.com/sync`;

  // Protocols should be smart enough to get a port if ones not already initialised
  // maybe store on the window once initialised?
  const http = new HTTPClient({ port, config: { timeout } });
  // https://github.com/sindresorhus/ky#kyinput-options
  const request = {
    headers: {
      'x-API-key': apiKey, // should headers be included in request building?
    },
    url: endpoint,
    method: 'post',
    data: payload,
    timeout: timeout * 1000,
  };

  const { data: response } = await http.send({ payload: request });
  return response;
};

export { executeRequest };
