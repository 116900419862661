const buildRequestOptions = ({ endpoint, localIp, payload, timeout, jwtToken, ip } = {}) => {
  const formattedEndpoint = localIp ? endpoint.replace('{ip}', ip) : endpoint;

  return {
    endpoint: formattedEndpoint,
    params: {
      json: payload,
      timeout: timeout * 1000,
      token: `Bearer ${jwtToken}`,
      ...(localIp && { prefixUrl: '' }),
    },
  };
};

export { buildRequestOptions };
