import {
  retry,
  timeout,
  request,
  panthorEndpoint
} from '@fingermarkglobal/request';
import {
  RestaurantMenuCount,
  ChromeSerialSettings
} from '@fingermarkglobal/types';
import { getJwtToken } from '@fingermarkglobal/utilities';

const getProductsRequest = async (
  count: RestaurantMenuCount,
  settings: ChromeSerialSettings
) => {
  const organisationId = process.env.POI_APP_ORGANISATION_ID || null;
  const { storeId: id } = settings.settings.datasource;
  const { numberOfProductsPage: pages } = count;
  const token = await getJwtToken();

  const requests = [];

  // This fixes some tests but breaks other things
  // return [];

  for (let page = 1; page <= pages; page++) {
    requests.push(
      request
        .get(
          `${panthorEndpoint}/menu/products/${organisationId}/${id}/page/${page}`,
          {
            retry,
            timeout,
            token: `Bearer ${token}`,
            headers: {
              'x-api-apex-logging-project': process.env.POI_APP_LOGGER_PROJECT
            }
          }
        )
        .json()
    );
  }

  const response = await Promise.all(requests);

  const products = response.flat(1);

  return products;
};

export { getProductsRequest };
