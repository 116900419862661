const getResultMessage = () => {
  return {
    rawResponse: null,
    success: false,
    message: null,
  };
};

const getMessageCategory = message => {
  return message?.MessageCategory || '';
};

const readMessage = message => {
  const result = getResultMessage();

  if (message?.Result !== 'Success') {
    throw new Error(message?.MerDescData);
  }

  result.success = true;
  result.rawResponse = message;

  return result;
};

const parseMessage = buffer => {
  const toString = Buffer.from(buffer.buffer, 'hex').toString();
  const message = JSON.parse(toString);
  const response = message?.SaleToPOIResponse || {};

  const category = getMessageCategory(response?.MessageHeader);

  switch (category) {
    case 'Payment': {
      return readMessage(response?.PaymentResponse);
    }
    default:
      throw new Error('Unknown message category');
  }
};

export { parseMessage };
