import { request } from '@fingermarkglobal/request';

const createClient = (config = {}) => ({
  appendEvents: async (stream, expectedVersion, events = []) => {
    const endpoint = `${config.endpoint}/append_events`;

    try {
      await request
        .post(endpoint, {
          json: events,
          timeout: 30 * 1000,
        })
        .json();
    } catch (err) {
      logger.warn(err);
    }
  },
});

export { createClient };
