import { validate } from '@fingermarkglobal/validation';
import { request } from '@fingermarkglobal/request';

const sendSMS = async ({ countryCode, phoneNumber, message, settings }) => {
  try {
    const { endpoint = null, password = null, source = null, username = null } =
      settings?.routemobile || {};

    validate({
      name: 'sendSMS',
      parameters: { endpoint, source, username, password, phoneNumber, message },
    });

    const routemobileUrl = `${endpoint
      .replace('{{phoneNumber}}', `+${countryCode}${phoneNumber}`)
      .replace('{{source}}', source)
      .replace('{{username}}', username)
      .replace('{{password}}', password)
      .replace('{{message}}', message)}&type=2`;

    const response = await request.get(routemobileUrl, { useToken: false }).text();
    const data = response || '';

    if (data.split('|')[0] !== '1701') throw new Error(response);

    return response;
  } catch (error) {
    throw new Error(`[sendSMS] Fail to handle request: ${error}`);
  }
};

export { sendSMS };
