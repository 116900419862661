import { nanoid } from 'nanoid';
import {
  createAdder,
  createEditor,
  createRemover,
  createSelector,
  createSetValidatedValues,
  createRemoverAll,
} from './handlers';
import { formatCategory } from './utilities';
import { validate } from '@fingermarkglobal/validation';

// `format` goes from a hydrated product to a formatted, actionable
// product (add, remove etc)
const format = ({
  product,
  update,
  category = null,
  isRootProduct = false,
  categoryUid = null,
} = {}) => {
  validate({ name: 'Cart format', paramaters: { product, update } });
  const {
    id,
    productId,
    min = 0,
    max = -1,
    name = {},
    options = [],
    products = [],
    uid = nanoid(),
    categories = [],
    defaultQuantity = 1,
    type = 'No type found',
    imageUrl = 'No image found',
    price: unitPrice = 'No price found',
    default: initial = false,
    providerData = {},
    energyInformation,
    description = {},
    filterName = {},
    sku,
  } = product;

  // if `category` is present it comes from a customisation
  // category, this means it has extra maxes
  const { max: categoryMax = null } = category || {};
  const initialDefault = initial ? defaultQuantity : max === min ? max : 0;

  const count = {
    min,
    max,
    current: isRootProduct ? 1 : initialDefault,
    initial: defaultQuantity,
  };
  const { current } = count;

  const priceCalc = Math.max(0, count.current - count.initial) * unitPrice;

  const price = {
    initial: isRootProduct ? 0 : priceCalc,
    current: isRootProduct ? unitPrice : priceCalc,
    unit: unitPrice,
  };

  const selected = current > 0;

  const remove = createRemover({ uid, update, isRootProduct });
  const select = createSelector({ uid, update, categoryUid });
  const add = createAdder({ uid, update, categoryMax, isRootProduct });
  const removeAll = createRemoverAll({ uid, update });
  const edit = createEditor({ uid, update });
  const setValidatedValues = createSetValidatedValues({ uid, update });

  const formatted = products.map(product => format({ product, update }));

  const formattedOptions = options.map(category => formatCategory({ category, update, id }));

  const formattedCustomisations = categories.map(category =>
    formatCategory({ category, update, id }),
  );

  return {
    productId,
    id,
    uid,
    add,
    type,
    name,
    count,
    remove,
    removeAll,
    select,
    edit,
    setValidatedValues,
    selected,
    imageUrl,
    providerData,
    default: initial,
    products: formatted,
    options: formattedOptions,
    price,
    isRootProduct,
    customisations: formattedCustomisations,
    removeDisabled: isRootProduct ? current <= 1 : current <= min,
    isAvailable: true,
    validated: false,
    addDisabled: max !== -1 && current >= max,
    energyInformation,
    description,
    editDisabled: type === 'simple',
    filterName,
    evaluated: false,
    sku,
  };
};

export { format };
