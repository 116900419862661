import { check, validate } from '@fingermarkglobal/validation';
import { interpret, getJwtToken } from '@fingermarkglobal/utilities';
import { request } from '@fingermarkglobal/request';
import { placeOrderMachine as machine } from './machines/place-order';
import { buildRequestOptions } from './helpers';

const doPlaceOrder = ({ payload = null, timeout = 30, transition = null, ip }) => {
  validate({ name: 'doPlaceOrder', parameters: { payload } });

  const { instance: placeOrderMachine } = interpret({
    name: 'placeOrderMachine',
    machine,
    transition,
  });

  // IIFE so we can return the running machine
  (async () => {
    const functionName = 'place-order';

    const invalidParams = check({
      name: 'Place-order',
      parameters: { payload },
    });

    placeOrderMachine.send('INITIALISE');

    if (invalidParams) {
      placeOrderMachine.send('INITIALISE_ERROR', {
        message: `[${functionName}] Fail to validate parameters [${invalidParams}]`,
      });
    }

    const endpoint = process.env.POI_APP_HAWKE_PLACE_ORDER_ENDPOINT;
    const localIp = JSON.parse(process.env.POI_APP_HAWKE_ORDER_LOCAL_IP || 'false');

    if (!endpoint)
      placeOrderMachine.send('INITIALISE_ERROR', {
        message: `[${functionName}] Failed to get place order endpoint in the environment file with the key POI_APP_HAWKE_PLACE_ORDER_ENDPOINT.`,
      });

    placeOrderMachine.send('INITIALISE_SUCCESS');

    try {
      const jwtToken = await getJwtToken();

      if (!jwtToken) {
        placeOrderMachine.send('PROCESS_ERROR', {
          message: `[${functionName}] Request made successfully, but an empty response was received`,
        });
      }

      logger.log(`[place-order] request payload: ${JSON.stringify(payload, null, 2)}`);

      const { endpoint: formattedEndpoint, params } = buildRequestOptions({
        endpoint,
        localIp,
        payload,
        timeout,
        jwtToken,
        ip,
      });

      const response = await request.post(formattedEndpoint, params).json();

      if (!response) {
        placeOrderMachine.send('PROCESS_ERROR', {
          message: `[${functionName}] Request failed. Response {${response}}`,
        });
      }

      logger.log(`[place-order] response payload: ${JSON.stringify(response, null, 2)}`);

      placeOrderMachine.send('PROCESS_SUCCESS', { message: response });
      placeOrderMachine.send('IDLE');
    } catch (error) {
      const throwError = message =>
        placeOrderMachine.send('PROCESS_ERROR', {
          message: `[${functionName}] Failed to handle request [${message}]`,
        });

      if (error?.response) error.response.json().then(data => throwError(data.message));
      // if contains `response` prop it is http error
      else throwError(error);
    }
  })();

  return { placeOrderMachine };
};

export { doPlaceOrder };
