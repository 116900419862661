import { ChromeSerialSettings } from '@fingermarkglobal/types';
import { getJwtToken } from '@fingermarkglobal/utilities';
import { retry, timeout, request, panthorEndpoint } from '@fingermarkglobal/request';

const getCategoriesRequest = async (settings: ChromeSerialSettings) => {
  const organisationId = process.env.POI_APP_ORGANISATION_ID || null;
  const { storeId: id } = settings.settings.datasource;
  const token = await getJwtToken();

  const categories = await request
    .get(`${panthorEndpoint}/menu/categories/${organisationId}/${id}`, {
      retry,
      timeout,
      token: `Bearer ${token}`,
      headers: {
        'x-api-apex-logging-project': process.env.POI_APP_LOGGER_PROJECT,
      },
    })
    .json();

  return categories;
};

export { getCategoriesRequest };
