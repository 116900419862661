import { assign } from 'xstate';

const handleError = assign((context, event) => {
  return {
    ...context,
    message: event.data?.message, // catches `Error` objects that are thrown as it cant automatically destrucutre the `message` prop
    ...event.data,
  };
});

export { handleError };
