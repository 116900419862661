import { isArray, isEqual } from 'lodash';
import { atom, selector } from 'recoil';
import { trim } from '../utilities/trim';
import { destruct } from '../utilities/destruct';

import { evaluate } from '../utilities/evaluate';
import { stack } from '../utilities/stack';

// `effects_UNSTABLE` is the key Recoil uses for extra attom effects
// This util is to make this easier to understand, and so I dont have
// to make this comment everywhere.
const atomEffects = effects => ({ effects_UNSTABLE: effects });

const cartState = atom({
  key: 'cartState',
  ...atomEffects([
    ({ onSet }) => {
      onSet(cart => {
        logger.debug('Cart', { cart });
      });
    },
    ({ onSet, setSelf }) => {
      onSet((products, previous) => {
        if (!isArray(products)) return;
        // Stops infite loop of set > evaluate > set...
        if (isEqual(products, previous)) return;
        setSelf(
          products
            .filter(product => product.count.current > 0)
            .map(product => evaluate({ product }))
            .reduce(stack, []),
        );
      });
    },
  ]),
  default: [],
});

const secondaryCartState = atom({
  key: 'secondaryCartState',
  ...atomEffects([
    ({ onSet }) => {
      onSet(cart => {
        logger.debug('Secondary cart', { cart });
      });
    },
    ({ onSet, setSelf }) => {
      onSet((products, previous) => {
        if (!isArray(products)) return;
        // Stops infite loop of set > evaluate > set...
        if (isEqual(products, previous)) return;
        setSelf(
          products
            .filter(product => product.count.current > 0)
            .map(product => evaluate({ product }))
            .reduce(stack, []),
        );
      });
    },
  ]),
  default: [],
});

const formattedCartState = selector({
  key: 'formattedCartState',
  get: ({ get }) => {
    const cart = get(cartState);

    const destructered = cart.map(destruct);
    const formatted = destructered.map(trim);

    logger.debug('Formatted cart', { cart: formatted });
    return formatted;
  },
});

export { cartState, formattedCartState, secondaryCartState };
