import { assign } from 'xstate';
import { buildRefund } from '../../../utilities/builders';

const createPayload = assign((context, event) => {
  const { transactionId, timestamp, config } = event;
  const payload = buildRefund({ transactionId, timestamp, config });

  return {
    config,
    request: payload,
  };
});

export { createPayload };
