import { check } from '@fingermarkglobal/validation';
import { NewEvent } from '@fingermarkglobal/event-store';
import { defaultRequest, publishEvent } from './default';
import { encodeProductDetails } from './util';

const registerProductAddedToCart = (
  { eventStoreEnabled },
  {
    sessionId,
    productVariant,
    categoryName,
    product,
    price,
    quantity,
    listName,
    storeAddress,
    organisation,
    serial,
    upsell,
  } = {},
) => {
  const invalidParams = check({
    name: 'Register product added to cart',
    parameters: {
      sessionId,
      productVariant,
      categoryName,
      product,
      price,
      quantity,
      listName,
      storeAddress,
      organisation,
      serial,
      upsell,
    },
  });

  if (invalidParams) {
    logger.log(`[registerProductAddedToCart] Invalid params [${invalidParams}]`);
    return;
  }

  const additionalPayload = {
    t: 'event',
    ec: 'Ecommerce',
    ea: 'Add to Cart',
    pa: 'add',
  };

  const detailsToEncode = {
    eventAction: 'ProductAddedToCard',
    index: 1,
    sessionId,
    storeAddress,
    serial,
    listName,
    productVariant,
    organisation,
    categoryName,
    productName: product.name,
    productSku: product.id,
    upsell,
    price,
    quantity,
    additionalPayload,
  };

  const encodedPayload = encodeProductDetails(detailsToEncode);

  Object.keys(additionalPayload).forEach(name => {
    encodedPayload.push(`${name}=${encodeURIComponent(additionalPayload[name])}`);
  });

  defaultRequest(encodedPayload.join('&'));

  if (eventStoreEnabled) {
    const e = NewEvent(
      process.env.POI_APP_EVENT_STORE_SOURCE,
      process.env.POI_APP_EVENT_STORE_APP,
      'UserInteractionRecorded',
      {},
      detailsToEncode,
    );

    publishEvent(e);
  }
};

export { registerProductAddedToCart };
