import { assign } from 'xstate';
import { buildPayment } from '../../../utilities/builders';

const createPayload = assign((_, { amount, config }) => {
  const payload = buildPayment({ amount, config });

  return {
    config,
    request: payload,
  };
});

export { createPayload };
