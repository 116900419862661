import { v4 as uuid } from 'uuid';

const connect = (port, ip, tcpPort, identifier) => {
  logger.log(`[tcp]: creating tcp connection to [${ip}] in port [${tcpPort}]`);

  port.postMessage({
    method: 'create',
    tcpPort,
    ip,
    id: identifier,
    topic: 'request/tcp',
  });

  logger.log(`[tcp]: tcp connection created`);
};

const disconnect = (port, handler) => {
  const identifier = uuid();

  logger.log(`[tcp]: disconnecting port`);

  port.onMessage.removeListener(handler);

  port.postMessage({
    method: 'clear',
    id: identifier,
    topic: 'request/tcp',
  });

  logger.log(`[tcp]: port disconnected`);
};

const send = (payload, port, socket, handler) => {
  const identifier = uuid();

  logger.log(`[tcp]: sending message to port [${payload}]`);

  if (!socket) {
    disconnect(port, handler);
    throw new Error('Socket not defined');
  }

  port.postMessage({
    method: 'write',
    socket,
    payload,
    id: identifier,
    topic: 'request/tcp',
  });

  logger.log(`[tcp]: message sent`);
};

const addListener = (port, handler) => {
  logger.log(`[tcp]: adding listener to port`);

  port.onMessage.addListener(handler);
};

export { send, disconnect, addListener, connect };
