// Routes is for the applications workflow definitions.
import React from 'react';

import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom';

import {
  DefaultTemplate,
  RootViewProvider,
  RestaurantHomeView,
  RestaurantHomeViewProvider,
  RestaurantBeaconView,
  RestaurantBeaconViewProvider,
  RestaurantCategoriesView,
  RestaurantCategoriesViewProvider,
  RestaurantCategoryViewProvider,
  RestaurantCategoryView,
  RestaurantProductViewProvider,
  RestaurantProductComboView,
  RestaurantProductComboViewProvider,
  RestaurantProductView,
  RestaurantCartView,
  RestaurantCartViewProvider,
  RestaurantOrderValidationView,
  RestaurantOrderValidationViewProvider,
  RestaurantOrderConfirmationView,
  RestaurantOrderConfirmationViewProvider,
  AdminView,
  AdminViewProvider,
  RestaurantLockView,
  RestaurantLockViewProvider,
  ReceiptView,
  ReceiptViewProvider,
  RestaurantCustomerDetailsView,
  RestaurantCustomerDetailsViewProvider,
  SmsView,
  SmsViewProvider,
  PaymentOptionsView,
  PaymentOptionsViewProvider,
  CashPaymentView,
  CashPaymentViewProvider,
  CardPaymentView,
  CardPaymentViewProvider,
  RefundViewProvider,
  RefundView,
  ResetViewProvider,
  ResetView,
  NotificationProvider,
  NotificationView,
  AdminAuthenticateView,
  AdminAuthenticateProvider,
  InvalidRouteView,
  InvalidRouteViewProvider,
  TransactionsView,
  TransactionsViewProvider,
} from '@fingermarkglobal/cringer.components';

import { version } from './package.json';

const Routes = () => {
  return (
    <Router>
      <RootViewProvider>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route exact path="/reset">
            <ResetViewProvider>
              <ResetView />
            </ResetViewProvider>
          </Route>
          <Route exact path="/home">
            <RestaurantHomeViewProvider>
              <DefaultTemplate headerEnabled={false} footerEnabled={false} timeoutEnabled={false}>
                <RestaurantHomeView />
              </DefaultTemplate>
            </RestaurantHomeViewProvider>
          </Route>
          <Route exact path="/beacon">
            <RestaurantBeaconViewProvider primaryPath="/categories" secondaryPath="/reset">
              <DefaultTemplate>
                <RestaurantBeaconView />
              </DefaultTemplate>
            </RestaurantBeaconViewProvider>
          </Route>
          <Route exact path="/categories">
            <RestaurantCategoriesViewProvider>
              <DefaultTemplate>
                <RestaurantCategoriesView />
              </DefaultTemplate>
            </RestaurantCategoriesViewProvider>
          </Route>
          <Route exact path="/category/:id">
            <RestaurantCategoryViewProvider>
              <DefaultTemplate>
                <RestaurantCategoryView />
              </DefaultTemplate>
            </RestaurantCategoryViewProvider>
          </Route>
          <Route
            exact
            path={['/category/:categoryId/product/:id', '/product/:id', '/product/:id/cart/:uid']}
          >
            <RestaurantProductViewProvider>
              <DefaultTemplate>
                <RestaurantProductView />
              </DefaultTemplate>
            </RestaurantProductViewProvider>
          </Route>
          <Route
            exact
            path={[
              '/product/:productId/combo/:filterId',
              '/product/:productId/combo/:filterId/cart/:uid',
            ]}
          >
            <RestaurantProductComboViewProvider>
              <DefaultTemplate>
                <RestaurantProductComboView />
              </DefaultTemplate>
            </RestaurantProductComboViewProvider>
          </Route>
          <Route exact path="/validate">
            <RestaurantOrderValidationViewProvider>
              <DefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                timeoutEnabled={false}
                templateOverride={true}
              >
                <RestaurantOrderValidationView />
              </DefaultTemplate>
            </RestaurantOrderValidationViewProvider>
          </Route>
          <Route exact path="/cart">
            <RestaurantCartViewProvider showCategoriesSlider={true}>
              <DefaultTemplate>
                <RestaurantCartView />
              </DefaultTemplate>
            </RestaurantCartViewProvider>
          </Route>
          <Route exact path="/payment-options">
            <PaymentOptionsViewProvider>
              <DefaultTemplate headerEnabled={false} footerEnabled={false}>
                <PaymentOptionsView />
              </DefaultTemplate>
            </PaymentOptionsViewProvider>
          </Route>
          <Route exact path="/cash-payment">
            <CashPaymentViewProvider>
              <DefaultTemplate timeoutEnabled={false} headerEnabled={false} footerEnabled={false}>
                <CashPaymentView />
              </DefaultTemplate>
            </CashPaymentViewProvider>
          </Route>
          <Route exact path="/card-payment">
            <CardPaymentViewProvider>
              <DefaultTemplate timeoutEnabled={false} headerEnabled={false} footerEnabled={false}>
                <CardPaymentView />
              </DefaultTemplate>
            </CardPaymentViewProvider>
          </Route>
          <Route exact path="/refund">
            <RefundViewProvider nextPath="/reset">
              <DefaultTemplate
                timeoutEnabled={false}
                headerEnabled={false}
                footerEnabled={false}
                templateOverride={true}
              >
                <RefundView />
              </DefaultTemplate>
            </RefundViewProvider>
          </Route>
          <Route exact path="/checkout">
            <RestaurantOrderConfirmationViewProvider nextPath="/receipt">
              <DefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                timeoutEnabled={false}
                templateOverride={true}
              >
                <RestaurantOrderConfirmationView />
              </DefaultTemplate>
            </RestaurantOrderConfirmationViewProvider>
          </Route>
          <Route exact path="/receipt">
            <ReceiptViewProvider>
              <DefaultTemplate headerEnabled={false} footerEnabled={false}>
                <ReceiptView />
              </DefaultTemplate>
            </ReceiptViewProvider>
          </Route>
          <Route exact path="/admin">
            <AdminViewProvider version={version}>
              <DefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                accessibilityEnabled={false}
              >
                <AdminView />
              </DefaultTemplate>
            </AdminViewProvider>
          </Route>
          <Route exact path="/notify">
            <NotificationProvider>
              <DefaultTemplate headerEnabled={false} footerEnabled={false} timeoutEnabled={false}>
                <NotificationView />
              </DefaultTemplate>
            </NotificationProvider>
          </Route>
          <Route exact path="/auth">
            <AdminAuthenticateProvider>
              <DefaultTemplate headerEnabled={false} footerEnabled={false} timeoutEnabled={true}>
                <AdminAuthenticateView />
              </DefaultTemplate>
            </AdminAuthenticateProvider>
          </Route>
          <Route exact path="/lock">
            <RestaurantLockViewProvider>
              <RestaurantLockView />
            </RestaurantLockViewProvider>
          </Route>
          <SmsViewProvider exact path="/sms">
            <DefaultTemplate headerEnabled={false} footerEnabled={false}>
              <SmsView />
            </DefaultTemplate>
          </SmsViewProvider>
          <Route exact path="/customer-details">
            <RestaurantCustomerDetailsViewProvider
              isFirstNameRequired={true}
              isLastNameRequired={true}
            >
              <DefaultTemplate>
                <RestaurantCustomerDetailsView />
              </DefaultTemplate>
            </RestaurantCustomerDetailsViewProvider>
          </Route>
          <Route exact path="/transactions">
            <TransactionsViewProvider>
              <DefaultTemplate headerEnabled={false} footerEnabled={false}>
                <TransactionsView />
              </DefaultTemplate>
            </TransactionsViewProvider>
          </Route>
          <Route>
            <InvalidRouteViewProvider>
              <InvalidRouteView />
            </InvalidRouteViewProvider>
          </Route>
        </Switch>
      </RootViewProvider>
    </Router>
  );
};

export { Routes };
