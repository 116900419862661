import { request, parseMessage } from '../../../utilities';

const executePayment = async context => {
  logger.log(`[marshal] executePayment: context [${JSON.stringify(context)}]`);

  const { buffer = '', config = {} } = context || {};

  const response = await request({ buffer, config });

  const fields = parseMessage(response);

  logger.log(`[marshal] executePayment: parsed message fields [${JSON.stringify(fields)}]`);

  const { responseCode = '', responseMessage = '' } = fields || {};

  if (responseCode !== '000' && responseCode !== '00') {
    throw new Error(responseMessage);
  }

  return { fields };
};

export { executePayment };
