import {
  calculateLRC,
  commandCode,
  getHexLengthBytes,
  getPayloadLengthBytes,
  toHex,
} from './utils';

const createPurchaseRequest = amountInCents => {
  const mid = 0;

  const txId = Number(
    new Date()
      .getTime()
      .toString()
      .substr(-5),
  );

  const messageIdentifier = toHex('V2');

  const requestMessage = [];
  requestMessage.push(commandCode.PURCHASE);
  requestMessage.push(txId.toString());
  requestMessage.push(mid.toString());
  requestMessage.push(amountInCents.toString());

  const payload = Buffer.from(requestMessage.join());
  const payloadHex = payload.toString('hex');

  // Longitudinal redundancy check (LRC)
  const calculatedLRC = calculateLRC(payloadHex);

  // Payload Length in Bytes
  const payloadLengthBytes = getPayloadLengthBytes(payload.length);
  const hexLengthBytes = getHexLengthBytes(payloadLengthBytes);
  const buffer = messageIdentifier + hexLengthBytes + payloadHex + calculatedLRC;

  const response = {
    buffer,
    txId,
  };

  return response;
};

export { createPurchaseRequest };
