import { createMachine, assign } from 'xstate';

const populateMessage = assign({
  message: (context, { message }) => {
    const { message: error } = message || {};
    if (error) return error;
    return message;
  },
});

const postOrderMachine = createMachine(
  {
    id: 'postOrder',
    initial: 'idle',
    context: {
      message: null,
    },
    states: {
      idle: {
        on: {
          INITIALISE: 'initialising',
        },
      },
      initialising: {
        on: {
          INITIALISE_SUCCESS: 'processing',
          INITIALISE_ERROR: {
            target: 'error',
            actions: ['populateMessage'],
          },
        },
      },
      processing: {
        on: {
          PROCESS_SUCCESS: {
            target: 'success',
            actions: ['populateMessage'],
          },
          PROCESS_ERROR: {
            target: 'error',
            actions: ['populateMessage'],
          },
          PROCESS_TIMEOUT: {
            target: 'timeout',
            actions: ['populateMessage'],
          },
        },
      },
      timeout: {
        on: {
          TIMEOUT_NO_RESPONSE: {
            target: 'error',
            actions: ['populateMessage'],
          },
        },
      },
      success: {
        on: {
          IDLE: 'idle',
        },
      },
      error: { type: 'final', entry: ['populateMessage'] },
    },
  },
  {
    actions: {
      populateMessage,
    },
  },
);

export { postOrderMachine };
