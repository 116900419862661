import { RestaurantMenuCount, ChromeSerialSettings } from '@fingermarkglobal/types';
import { getJwtToken } from '@fingermarkglobal/utilities';
import { retry, timeout, request, panthorEndpoint } from '@fingermarkglobal/request';

const getPromotionalRequest = async (count: RestaurantMenuCount, settings: ChromeSerialSettings) => {
  const organisationId = process.env.POI_APP_ORGANISATION_ID || null;
  const { storeId: id } = settings.settings.datasource;
  const token = await getJwtToken();

  const { numberOfMeals, numberOfUpsells, numberOfCoupons } = count;

  const mealsRequest =
    numberOfMeals > 0
      ? request
          .get(`${panthorEndpoint}/menu/meals/${organisationId}/${id}`, {
            retry,
            timeout,
            token: `Bearer ${token}`,
            headers: {
              'x-api-apex-logging-project': process.env.POI_APP_LOGGER_PROJECT,
            },
          })
          .json()
      : Promise.resolve([]);

  const upsellsRequest =
    numberOfUpsells > 0
      ? request
          .get(`${panthorEndpoint}/menu/upsells/${organisationId}/${id}`, {
            retry,
            timeout,
            token: `Bearer ${token}`,
            headers: {
              'x-api-apex-logging-project': process.env.POI_APP_LOGGER_PROJECT,
            },
          })
          .json()
      : Promise.resolve([]);

  const couponsRequest =
    numberOfCoupons > 0
      ? request
          .get(`${panthorEndpoint}/menu/coupons/${organisationId}/${id}`, {
            retry,
            timeout,
            token: `Bearer ${token}`,
            headers: {
              'x-api-apex-logging-project': process.env.POI_APP_LOGGER_PROJECT,
            },
          })
          .json()
      : Promise.resolve([]);

  const requests = [mealsRequest, upsellsRequest, couponsRequest];
  const response = await Promise.all(requests);

  const [meals, upsells, coupons] = response;

  return {
    meals,
    upsells,
    coupons,
  };
};

export { getPromotionalRequest };
