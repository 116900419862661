import { validate } from '@fingermarkglobal/validation';

const sendSMS = ({ countryCode, phoneNumber, message }) => {
  try {
    validate({
      name: 'sendSMS',
      parameters: { countryCode, phoneNumber, message },
    });

    return new Promise(resolve => setTimeout(() => resolve('Success mock response'), 1000));
  } catch (error) {
    throw new Error(`[sendSMS] Fail to handle request: ${error}`);
  }
};

export { sendSMS };
