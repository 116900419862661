import { is, object, array, string, number, nullable, enums, optional } from 'superstruct';

const StatusEnum = enums([
  'ORDER_VALIDATION_SUCCESS',
  'ORDER_VALIDATION_ERROR',
  'ORDER_CONFIRMATION_SUCCESS',
  'ORDER_CONFIRMATION_ERROR',
  'ORDER_CANCELLED',
  'ORDER_PAYMENT_ERROR',
  'ORDER_PAYMENT_SUCCESS',
  'ORDER_REFUND_SUCCESS',
  'ORDER_REFUND_ERROR',
  'ORDER_REFUND_CANCELLED',
  'ORDER_POST_SUCCESS',
  'ORDER_POST_ERROR',
]);

const Transactions = array(
  object({
    cart: array(object()),
    message: optional(nullable(string())),
    payment: optional(nullable(object())),
    session: object(),
    status: StatusEnum,
    total: number(),
    receiptId: optional(nullable(string())),
  }),
);

const validate = (value = null) => {
  if (!value) return null;

  const valid = is(value, Transactions);

  if (!valid) throw new Error('Transactions value does not match Transactions schema');
  else return value;
};

export { Transactions, validate };
