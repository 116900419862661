import { nanoid } from 'nanoid';

const encode = ({ type = null, method = null, payload = null } = {}) => {
  const request = {
    id: nanoid(),
    topic: `request/${type}`, // "usb", "tcp" etc
    method, // "write", "read" etc
    payload,
  };

  return request;
};

const decode = ({ response = null } = {}) => {
  const { method, topic, payload } = response;

  return { method, topic, payload };
};

const matching = ({ request = null, response = null } = {}) => {
  // Ensure response is for correct request
  const { id: responseID } = response;
  const { id: requestID } = request;

  return responseID === requestID;
};

const dispatcher = ({ port = null, config = null } = {}) => ({ request = null } = {}) =>
  new Promise((resolve, reject) => {
    const { timeout = 30 } = config;

    const handler = response => {
      if (!matching({ request, response })) return null;
      logger.log(
        '[@fingermarkglobal/chrome-messages] handler matched response',
        decode({ response }),
      );
      return resolve(decode({ response }));
    };

    setTimeout(() => {
      port.onMessage.removeListener(handler);
      reject(new Error('Response Timeout'));
    }, timeout * 1000);

    port.onMessage.addListener(handler);
    port.postMessage(request);
  });

const listener = ({ chrome }) => ({ handler }) => {
  const listen = port => {
    port.onMessage.addListener(async request => {
      const response = await handler(request);

      port.postMessage(response);
    });
  };

  chrome.runtime.onConnectExternal.addListener(listen);
};

export { encode, listener, dispatcher };
