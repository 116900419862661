import { ChromeSerialSettings } from '@fingermarkglobal/types';
import { differenceInHours, parseISO } from 'date-fns';
import { getTransactions } from '@fingermarkglobal/storage';

const transactionsResolve = async (settings: ChromeSerialSettings): Promise<any[]> => {
  try {
    const { intervals = {} } = settings;
    const { transaction = {} } = intervals;
    const { delete: expiry = 48 } = transaction;

    const transactions =
      (await getTransactions('restaurant/transactions')) || [];

    // Filter saved transactions by expiry date from settings
    const latest = transactions.filter(transaction => {
      const { session = {} } = transaction;
      const { timestamp } = session;

      const age = differenceInHours(parseISO(timestamp), new Date());

      return age <= expiry;
    });

    return latest;
  } catch (err) {
    logger.error(err);
    // Gracefully fail on this if there is something wrong...
    return [];
  }
};

export { transactionsResolve };
