import { validate } from '@fingermarkglobal/validation';
import { resetCategoryProductTree } from '../utilities';

// add item by increasing count
const createAdder = ({ uid, update, categoryMax = null, isRootProduct = false } = {}) => () => {
  validate({ name: 'Cart Adder', paramaters: { uid, update } });
  const handler = (item, siblings) => {
    const { count } = item;
    const { current: currentCount, max, min } = count;
    const countMin = isRootProduct ? 1 : min;

    // check max isnt exceeded by siblings
    const total = siblings.reduce((result, item) => result + item.count.current, 0);

    // -1 is `unlimited`
    // auto set addDisabled to true when total siblings is equal than category max
    if (categoryMax && categoryMax !== -1 && total >= categoryMax)
      return {
        ...item,
        addDisabled: true,
      };

    const disabledActions = {
      removeDisabled: currentCount + 1 <= countMin,
      addDisabled: max !== -1 && currentCount + 1 >= max,
    };

    if (max !== -1 && currentCount >= max)
      return {
        ...item,
        ...disabledActions,
      };

    return {
      ...item,
      count: { ...count, current: currentCount + 1 },
      validated: false,
      ...disabledActions,
    };
  };

  update({ uid, handler });
};

// removes item by reducing count
const createRemover = ({ uid, update, isRootProduct = false } = {}) => () => {
  validate({ name: 'Cart Remover', paramaters: { uid, update } });
  const handler = item => {
    const { count } = item;
    const { current: currentCount, min, max } = count;
    const countMin = isRootProduct ? 1 : min;

    const disabledActions = {
      removeDisabled: currentCount - 1 <= countMin,
      addDisabled: max !== -1 && currentCount - 1 >= max,
    };

    if (currentCount <= min)
      return {
        ...item,
        ...disabledActions,
      };

    return {
      ...item,
      count: { ...count, current: currentCount - 1 },
      validated: false,
      ...disabledActions,
    };
  };

  update({ uid, handler });
};

// removes item by reducing count to 0
const createRemoverAll = ({ uid, update } = {}) => () => {
  validate({ name: 'Cart Remover All', paramaters: { uid, update } });
  const handler = item => {
    const { count } = item;
    return {
      ...item,
      count: { ...count, current: 0 },
    };
  };

  update({ uid, handler });
};

const createSelector = ({ uid, update, categoryUid } = {}) => ({
  resetDefaultToZero = false,
} = {}) => {
  validate({ name: 'Cart Selector', paramaters: { uid, update, categoryUid } });

  const pre = ({ uid: originUid, item }) =>
    resetCategoryProductTree({
      product: item,
      currentCategoryUid: categoryUid,
      originUid,
      resetDefaultToZero,
    });

  const handler = item => {
    const { count, price } = item;
    const { unit } = price;

    return {
      ...item,
      count: { ...count, current: Number(!count.current) },
      price: { ...price, current: unit },
      validated: false,
    };
  };

  update({ uid, handler, pre });
};

const createEditor = ({ uid, update } = {}) => ({ isEditing = false }) => {
  validate({ name: 'Cart Editor', paramaters: { uid, update } });
  const handler = item => {
    return {
      ...item,
      isEditing,
    };
  };

  update({ uid, handler });
};

const createSetValidatedValues = ({ uid, update } = {}) => ({ price, isAvailable = true }) => {
  validate({ name: 'Cart Set Validated Values', paramaters: { uid, update } });
  const handler = item => {
    return {
      ...item,
      price: { ...item.price, current: price },
      isAvailable,
      validated: true,
    };
  };

  update({ uid, handler });
};

export {
  createAdder,
  createRemover,
  createSelector,
  createRemoverAll,
  createEditor,
  createSetValidatedValues,
};
