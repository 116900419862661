import { destructPayment } from '../../destructerers/payment';
import { executeRequest } from '../request';

const executePayment = async context => {
  // take built payload
  const { config } = context;

  // make request
  logger.log(`[adyen] payment request: ${JSON.stringify(context, null, 2)}`);

  const adyenResponse = await executeRequest({ context });

  logger.log(`[adyen] payment response: ${JSON.stringify(adyenResponse, null, 2)}`);

  // handle destruct
  // return standard format
  const { result, errorCondition, response, transactionId, timestamp, info } = destructPayment({
    response: adyenResponse,
    config,
  });

  if (result.toLowerCase() !== 'success') {
    // Logging here for visibility, error can get obfuscated via machine
    logger.error('[@fingermarkglobal/adyen] Refusal reason', errorCondition);
    throw new Error(errorCondition);
  }

  return {
    result,
    message: errorCondition,
    errorCondition,
    response,
    transactionId,
    timestamp,
    info,
  };
};

export { executePayment };
