import { setSerial, getSerial } from '../caching';

const persistSerialToCache = async (serial: string): Promise<void> => {
    return await setSerial('serial', serial);
}

const retrieveSerialFromCache = async (): Promise<null | string> => {
    return await getSerial('serial');
}

export {
    persistSerialToCache,
    retrieveSerialFromCache
}