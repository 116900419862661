import { check } from '@fingermarkglobal/validation';
import { defaultRequest } from './default';
import { getCustomisations } from './get-customisations';
import { encodeProductDetails } from './util';

const registerProductPurchase = (
  options,
  {
    totalRevenue,
    sessionId,
    products,
    storeAddress,
    organisation,
    serial,
    paymentType,
    orderNumber,
  } = {},
) => {
  const invalidParams = check({
    name: 'Register product purchase',
    parameters: {
      totalRevenue,
      sessionId,
      products,
      storeAddress,
      organisation,
      serial,
      paymentType,
      orderNumber,
    },
  });

  if (invalidParams) {
    logger.log(`[registerProductPurchase] Invalid params [${invalidParams}]`);
    return;
  }

  const additionalPayload = [];

  const details = {
    sessionId,
    storeAddress,
    serial,
  };

  additionalPayload.push(...encodeProductDetails(details));

  let hasUpsell = false;

  products.forEach((item, index) => {
    const isUpsell = item.upsell === 'upsell';
    if (isUpsell) hasUpsell = true;

    const customisations = [];
    const productsSelected = item?.productsSelected || {};

    Object.keys(productsSelected).forEach(key => {
      const selected = `productsSelected.${key}`;

      const productSelected = item[selected] || null;
      const productSelectedCategories = productSelected?.product?.customise?.categories || [];
      const tempCst = getCustomisations(
        productSelectedCategories,
        item.customisations ? item.customisations[key] : [],
      );

      Object.assign(customisations, [customisations, tempCst]);

      return null;
    });

    if (item.product.type === 'customise' && item.customisations) {
      const productSelectedCategories = item?.product?.customise?.categories || [];
      const tempCst = getCustomisations(productSelectedCategories, item.customisations);
      Object.assign(customisations, [customisations, tempCst]);
    }

    const detailsToEncode = {
      index,
      productVariant: item.product.type === 'combo' && item.filter ? item.filter.name.text : '',
      organisation,
      categoryName: item.categoryNameAnalytics,
      productName: item.product.name,
      productSku: item.product.id,
      price: item.totalPrice,
      quantity: item.quantity,
      upsell: isUpsell ? 'upsell' : 'normal',
      customDetail4: customisations.length > 0 ? 'customised' : 'normal',
    };

    additionalPayload.push(...encodeProductDetails(detailsToEncode));
  });

  additionalPayload.push(`cd5=${hasUpsell ? 'upsell' : 'normal'}`);
  additionalPayload.push(`cd6=${paymentType}`);
  additionalPayload.push(`cd7=${orderNumber}`);

  additionalPayload.push(`ti=${sessionId}`);
  additionalPayload.push(`tr=${totalRevenue}`);
  additionalPayload.push(`pa=purchase`);
  additionalPayload.push(`dp=%2FConfirmationt%20Order%20Number`);
  additionalPayload.push(`dt=Confirmationt%20Order%20Number`);

  additionalPayload.push(`t=event`);
  additionalPayload.push(`ec=Ecommerce`);
  additionalPayload.push(`ea=Purchase`);
  additionalPayload.push(`ni=1`);

  defaultRequest(additionalPayload.join('&'));
};

export { registerProductPurchase };
