import { evaluate } from '../evaluate';
import { isExactProduct } from './utilities';

const stack = (pastProducts, currentProduct) => {
  const exactProductIndex = pastProducts.findIndex(product =>
    isExactProduct(product, currentProduct),
  );

  if (exactProductIndex < 0) return [...pastProducts, currentProduct];

  const exactProduct = pastProducts[exactProductIndex];

  const { count: pastProductCount } = exactProduct;
  const { count: newProductCount } = currentProduct;
  const clone = [...pastProducts];
  const newProduct = {
    ...exactProduct,
    validated: false, // un validate pricing if stack has occured
    count: { ...pastProductCount, current: pastProductCount.current + newProductCount.current },
  };
  clone.splice(exactProductIndex, 1, newProduct);

  return clone.map(product => evaluate({ product }));
};

export { stack };
