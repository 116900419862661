import { request } from '@fingermarkglobal/request';
import { validate } from '@fingermarkglobal/validation';

const getJwtToken = async () => {
  const jwtEndpoint = process.env.POI_APP_JWT_ENDPOINT || null;

  validate({ name: 'getJwtToken', parameters: { jwtEndpoint } });

  const response = await request
    .get(jwtEndpoint, {
      headers: {
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      },
    })
    .text();

  return response;
};

export { getJwtToken };
