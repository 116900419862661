import { request, parseMessage } from '../../../utilities';

const executeRefund = async context => {
  logger.log(`[marshal] executeRefund: context [${JSON.stringify(context)}]`);

  const { buffer = '', config = {} } = context || {};

  const response = await request({ buffer, config });

  const fields = parseMessage(response);

  const { responseCode = '', responseMessage = '' } = fields || {};

  if (responseCode !== '000' && responseCode !== '00') {
    throw new Error(responseMessage);
  }

  return { fields };
};

export { executeRefund };
