import { persistSerialToCache } from '@fingermarkglobal/platforms.web';
import {
  SerialActionEvent,
  SerialMachineContext
} from '@fingermarkglobal/types';
import { assign, sendParent } from 'xstate';

// Wrap even in data as it is returned from a promise...
const setSerialAction = assign((_, event: { data: SerialActionEvent }) => ({
  port: event?.data?.port,
  origin: event?.data?.origin,
  serial: event?.data?.serial
}));

const setQueryErrorMessage = assign(() => ({
  message: `Failed to resolve serial via query string.`
}));

const setCacheErrorMessage = assign(() => ({
  message: `Failed to resolve serial via cache.`
}));

const setChromeErrorMessage = assign((_, event: { data: string }) => ({
  message: event.data
}));

const sendParentFailureAction = sendParent((context: SerialMachineContext) => ({
  type: 'SERIAL_MACHINE_FAILURE',
  message: context.message
}));

const sendParentCompletedAction = sendParent(
  (context: SerialMachineContext) => ({
    type: 'SERIAL_MACHINE_SUCCESS',
    port: context?.port,
    origin: context?.origin,
    serial: context?.serial
  })
);

const persistSerialToCacheAction = async (
  context: SerialMachineContext
): Promise<void> => {
  try {
    await persistSerialToCache(context?.serial);
    logger.log('Successfully persisted serial to the cache.');
  } catch (error) {
    logger.log('Failed to persist serial to the cache.');
  }
};

export {
  setSerialAction,
  setQueryErrorMessage,
  setCacheErrorMessage,
  setChromeErrorMessage,
  sendParentFailureAction,
  sendParentCompletedAction,
  persistSerialToCacheAction
};
