import { sender } from '@fingermarkglobal/chrome-messages';

class USBClient {
  constructor({ port = null, config = { timeout: 30, pid: null, vid: null } } = {}) {
    logger.log(
      `[usb]: creating USBClient instance with params [${JSON.stringify({ port, config })}]`,
    );

    const { vid, pid, timeout } = config;
    this.post = sender({ port, config });
    this.connectionId = null;
    this.vid = vid;
    this.pid = pid;
    this.timeout = timeout * 1000;

    logger.log(`[usb]: USBClient instance created`);
  }

  async connect() {
    const request = {
      type: 'usb',
      method: 'connect',
      payload: {
        timeout: this.timeout,
        vid: this.vid,
        pid: this.pid,
      },
    };

    logger.log(`[usb]: connect start [${JSON.stringify({ request })}]`);

    const { payload } = await this.post(request);
    const { connectionId } = payload || {};

    this.connectionId = connectionId;

    logger.log(`[usb]: connect finish [${JSON.stringify({ connectionId })}]`);
  }

  async disconnect() {
    const request = {
      type: 'usb',
      method: 'disconnect',
    };
    logger.log(`[usb]: disconnect start [${JSON.stringify({ request })}]`);

    await this.post(request);

    logger.log(`[usb]: diconnect finish`);
  }

  async write({ payload = {} }) {
    if (!this.connectionId) throw new Error('No USB connection');

    const request = {
      type: 'usb',
      method: 'write',
      payload: {
        connectionId: this.connectionId,
        timeout: this.timeout,
        ...payload,
      },
    };

    logger.log(`[usb]: write start [${JSON.stringify({ request })}]`);

    await this.post(request);

    logger.log(`[usb]: write finish`);
  }

  async read({ timeout = 30 * 1000, bufferTimeout } = {}) {
    if (!this.connectionId) throw new Error('No USB connection');

    const request = {
      type: 'usb',
      method: 'read',
      payload: {
        timeout: timeout || this.timeout,
        connectionId: this.connectionId,
        bufferTimeout,
      },
    };

    logger.log(`[usb]: read start [${JSON.stringify({ request })}]`);

    const response = await this.post(request);

    logger.log(`[usb]: read finish [${JSON.stringify({ response })}]`);

    return response;
  }

  async send({ payload = null, bufferTimeout } = {}) {
    logger.log(`[usb]: send start [${JSON.stringify({ payload, bufferTimeout })}]`);

    if (!this.connectionId) await this.connect();

    await this.write({ payload });

    const response = await this.read({ bufferTimeout });

    logger.log(`[usb]: send finish [${JSON.stringify({ response })}]`);

    return response;
  }
}

export { USBClient };
